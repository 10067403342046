<template>
	<div class="header_container">
		<i class="el-icon-s-fold" @click="clickMenuChange"></i>
		<div style="margin-left:16px;">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/mainpage' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item v-for="(item, index) in $route.meta" :key="index">{{item}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="top-icon-position">
			<el-popover
				placement="bottom-end"
				trigger="hover">
				<div class="top-icon-tip-text">占位</div>
				<el-badge slot="reference" :value="12" class="mark">
					<i class="el-icon-message-solid top-icon-style"></i>
				</el-badge>
			</el-popover>
		</div>
		<div class="top-login-position">
			<el-avatar :size="44" :src="useravatar"></el-avatar>
			<el-dropdown trigger="click" class="top-name-style">
				<span class="el-dropdown-link">
					{{username}}<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown" >
					<!-- <el-dropdown-item icon="el-icon-user" @click.native="userProfile">我的信息</el-dropdown-item>
					<el-dropdown-item icon="el-icon-question">操作指南</el-dropdown-item> -->
					<el-dropdown-item icon="el-icon-error" @click.native="logout">退出系统</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			username: '管理员', //JSON.parse(localStorage.getItem("env_admin_userInfo")).name,
			useravatar: 'http://yuezhuo.deaso40.com/mingjinglogo.png' //JSON.parse(localStorage.getItem("env_admin_userInfo")).information.avatar
		}
	},
	created(){

	},
	methods: {
		clickMenuChange(){
			this.$emit('changeMenuWidth'); // 由于是在elementui组件里，必须用$emit
		},
		logout(){
			console.log('logout');
			localStorage.removeItem("mingjing_admin_userInfo");
			this.$router.replace('/login')
		}
	}
}
</script>

<style scoped lang="less">
@deep: ~'>>>';
.header_container{
	background-color: #505559;
	height: 52px !important;
	display: flex;
	// justify-content: space-between;
	flex-wrap: nowrap;
	align-items: center;
	padding-left: 20px;
	@{deep} .el-dropdown-menu__item{
		text-align: center;
	}
	@{deep} .el-breadcrumb__inner.is-link{
		color: #dee1e8;
	}
	@{deep} .el-breadcrumb__inner{
		color: #dee1e8;
	}
	@{deep} .el-breadcrumb__item:last-child .el-breadcrumb__inner{
		color: #0f98ff;
	}
}
.avator{
	width: 36px;
	height: 36px;
	border-radius: 50%;
	margin-right: 37px;
}
.top-name-style{
	color: #ffffff;
	overflow: hidden;
	// margin-bottom: 12px;
	height: 30px;
	margin-left: 12px;
	font-weight: bold;
}
.top-icon-style{
	font-size: 24px;
	color: #ffffff;
	overflow: hidden;
}
.top-icon-position{
	margin-right: 50px;
	margin-top: 10px;
	text-align: right;
	flex: 1;
	visibility: hidden;
}
.top-icon-tip-text{
	margin: 4px 8px;
	// text-align: right;
}
.top-login-position{
	margin-top: 4px;
	margin-right: 32px;
	text-align: right;
}
</style>
