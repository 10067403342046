<template>
	<div class="fillcontain">
    <el-container class="fullheight">
      <el-aside width=null>
        <left-menu ref="leftMenu"></left-menu>
      </el-aside>
      <el-container>
        <el-header>
          <head-menu @changeMenuWidth="changeMenuWidth"></head-menu>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import headMenu from "@/components/headMenu";
import leftMenu from "@/components/leftMenu";

export default {
	data() {
		return {
			isCollapse: false,
		}
  },
  components: { // component给template的命名规则：大写转横杠+小写
    headMenu,
    leftMenu
  },
  methods: {
    changeMenuWidth() {
      this.$refs.leftMenu.changeWidth();
    }
  }
};
</script>


<style scoped lang="less">
@deep: ~'>>>';
.fullheight{
  min-height: 100vh;
}
.el-container {
	@{deep} .el-main{
		padding: 0;
  }
  @{deep} .el-header{
    padding: 0;
    height: 52px !important;
  }
  @{deep} .el-aside{
    background-color: #4f555a;
    border-right: solid 1px #e6e6e6;
  }
  @{deep} .el-menu {
    border-right: solid 0px #e6e6e6;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
}
}
</style>
